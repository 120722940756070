import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_firecracker_header = _resolveComponent("firecracker-header")!
  const _component_vocabulary_details = _resolveComponent("vocabulary-details")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_firecracker_header, {
        "header-title": _ctx.word,
        "content-id": "vocabulary-details",
        "menu-id": "vocabulary-details-menu",
        type: "BACK"
      }, null, 8, ["header-title"]),
      _createVNode(_component_ion_content, {
        fullscreen: true,
        id: "vocabulary-details"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_vocabulary_details, {
            word: _ctx.word,
            vocabulary: _ctx.vocabulary,
            "disable-inserting-into-leitner-box": _ctx.disableInsertingIntoLeitnerBox,
            "is-loading": _ctx.isLoading
          }, null, 8, ["word", "vocabulary", "disable-inserting-into-leitner-box", "is-loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}