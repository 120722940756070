import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fbccbd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "action-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    button: true,
    class: "margin-top-bottom-unset"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "contents",
            tabindex: "0",
            role: "button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.seeMore(_ctx.vocabulary.id, _ctx.vocabulary.word))),
            onKeydown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onKeyDown($event, _ctx.vocabulary.id, _ctx.vocabulary.word)))
          }, [
            _createVNode(_component_ion_card_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_title, { class: "capitalize overflowed-content" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.vocabulary.word), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card_content, null, {
              default: _withCtx(() => [
                (!_ctx.vocabulary.definitions.length)
                  ? (_openBlock(), _createBlock(_component_ion_card_subtitle, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(" No definition has been added yet! ")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.vocabulary.definitions.length)
                  ? (_openBlock(), _createElementBlock("view", _hoisted_1, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vocabulary.definitions.slice(0, 2), (definition) => {
                        return (_openBlock(), _createElementBlock("view", {
                          key: definition.id
                        }, [
                          _createVNode(_component_ion_label, { class: "capitalize display-flow-root overflowed-content" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(definition.meaning), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.vocabulary.definitions.length > 2)
                  ? (_openBlock(), _createBlock(_component_ion_card_subtitle, { key: 2 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: _ctx.faEllipsisH }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ], 32),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  "aria-label": "Remove vocabulary",
                  fill: "outline",
                  size: "small",
                  shape: "round",
                  strong: true,
                  color: "warning",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.presentAlertConfirm(_ctx.vocabulary)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: _ctx.faTrashAlt }, null, 8, ["icon"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_button, {
                  "aria-label": "Update vocabulary",
                  fill: "outline",
                  size: "small",
                  shape: "round",
                  strong: true,
                  color: "primary",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push(`/vocabulary/update/${_ctx.vocabulary.id}`)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: _ctx.faPencilAlt }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_row, { style: {"flex-wrap":"unset"} }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  "aria-label": "Create flashcard",
                  fill: "clear",
                  size: "small",
                  shape: "round",
                  strong: true,
                  disabled: _ctx.disableBoxInsertionButton,
                  color: "fern-green",
                  onClick: _ctx.insertIntoLeitnerBox
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: _ctx.vocabulary.isInLeitnerBox ? _ctx.faHeartSolid : _ctx.faHeartRegular,
                      class: "leitner-icon"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]),
                _createVNode(_component_ion_button, {
                  "aria-label": "View vocabulary details",
                  fill: "outline",
                  size: "small",
                  shape: "round",
                  strong: true,
                  color: "fern-green",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.seeMore(_ctx.vocabulary.id, _ctx.vocabulary.word)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: _ctx.faExpandAlt }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}