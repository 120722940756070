import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ee83ef2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "display-flex ion-justify-content-center ion-padding-bottom" }
const _hoisted_3 = { class: "link-container" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "updated-at ion-padding-start ion-padding-end" }
const _hoisted_7 = { class: "updated-at ion-padding-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_firecracker_header = _resolveComponent("firecracker-header")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_network_error = _resolveComponent("network-error")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_firecracker_header, {
        "header-title": _ctx.headerTitle,
        "content-id": "leitner-box-items",
        "menu-id": "leitner-box-items-menu",
        type: "BACK"
      }, null, 8, ["header-title"]),
      _createVNode(_component_ion_content, {
        fullscreen: true,
        id: "leitner-box-items"
      }, {
        default: _withCtx(() => [
          (_ctx.showSpinner && !_ctx.isNetworkError)
            ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.allQuietOnTheWesternFront && !_ctx.isNetworkError)
            ? (_openBlock(), _createElementBlock("view", _hoisted_1, [
                _createVNode(_component_ion_card_subtitle, { class: "ion-padding ion-text-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Things are pretty quiet right now. ")
                  ]),
                  _: 1
                }),
                _createElementVNode("view", _hoisted_2, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: _ctx.faGlassCheers,
                    class: "firecracker-primary-color-icon-large"
                  }, null, 8, ["icon"])
                ]),
                (_ctx.box === '1' && _ctx.totalItems === 0)
                  ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                      key: 0,
                      class: "ion-padding ion-text-center"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_3, [
                          _createTextVNode(" But beginning the journey with Leitner System is super easy. All you need to do is create a flashcard by pressing the "),
                          _createVNode(_component_font_awesome_icon, {
                            icon: _ctx.faHeartRegular,
                            class: "leitner-icon"
                          }, null, 8, ["icon"]),
                          _createTextVNode(" icon on the "),
                          _createVNode(_component_router_link, { to: "/vocabularies" }, {
                            default: _withCtx(() => [
                              _createTextVNode(" vocabulary card ")
                            ]),
                            _: 1
                          }),
                          _createTextVNode(". After that, the vocabulary will immediately place into the \"" + _toDisplayString(_ctx.headerTitle) + "\". ", 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.box !== '1' && _ctx.totalItems > 0)
                  ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                      key: 1,
                      class: "ion-padding ion-text-center"
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.singleLeitnerItemEarlierToBoxAppearanceDate?.vocabulary)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Items will only appear when it is the right time. "))
                          : _createCommentVNode("", true),
                        (_ctx.singleLeitnerItemEarlierToBoxAppearanceDate?.vocabulary)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Items will only appear when it is the right time. For example, the vocabulary \"" + _toDisplayString(_ctx.singleLeitnerItemEarlierToBoxAppearanceDate.vocabulary.word) + "\" will appear on " + _toDisplayString(_ctx.format(_ctx.singleLeitnerItemEarlierToBoxAppearanceDate.boxAppearanceDate, 'LLLL, dd')) + ". ", 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.boxItems, (boxItem) => {
            return (_openBlock(), _createBlock(_component_ion_row, {
              key: boxItem.vocabularyId,
              class: "display-flex ion-justify-content-center item-container"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, {
                  sizeXs: "12",
                  sizeSm: "12",
                  sizeMd: "8",
                  sizeLg: "6",
                  sizeXl: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card, { class: "margin-top-bottom-unset" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_header, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_title, { class: "display-flex ion-justify-content-center capitalize" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(boxItem.word), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_card_content, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_row, { class: "display-flex ion-justify-content-center ion-align-items-center capitalize ion-margin-top" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: _ctx.faCalendarAlt,
                                  class: "firecracker-primary-color-icon"
                                }, null, 8, ["icon"]),
                                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.format(boxItem.updatedAt, 'MMMM dd, yyyy')), 1),
                                _createVNode(_component_font_awesome_icon, {
                                  icon: _ctx.faClock,
                                  class: "firecracker-primary-color-icon"
                                }, null, 8, ["icon"]),
                                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.format(boxItem.updatedAt, 'hh:mm aa')), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                              default: _withCtx(() => [
                                (!_ctx.isFirstBox())
                                  ? (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 0,
                                      "aria-label": "Move to the previous box",
                                      fill: "outline",
                                      size: "small",
                                      shape: "round",
                                      strong: true,
                                      color: "warning",
                                      class: "ion-margin",
                                      onClick: ($event: any) => (_ctx.moveBackward(boxItem.vocabularyId))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_font_awesome_icon, { icon: _ctx.faThumbsDown }, null, 8, ["icon"])
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (!_ctx.isLastBox())
                                  ? (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 1,
                                      "aria-label": "Move to the next box",
                                      fill: "outline",
                                      size: "small",
                                      shape: "round",
                                      strong: true,
                                      color: "primary",
                                      class: "ion-margin",
                                      onClick: ($event: any) => (_ctx.moveForward(boxItem.vocabularyId))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_font_awesome_icon, { icon: _ctx.faThumbsUp }, null, 8, ["icon"])
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_ion_button, {
                                  "aria-label": "View vocabulary details",
                                  fill: "outline",
                                  size: "small",
                                  shape: "round",
                                  strong: true,
                                  color: "fern-green",
                                  class: "ion-margin",
                                  onClick: ($event: any) => (_ctx.seeMore(boxItem.vocabularyId, boxItem.word))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_font_awesome_icon, { icon: _ctx.faUnlockAlt }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128)),
          (!_ctx.allQuietOnTheWesternFront && _ctx.singleLeitnerItemEarlierToBoxAppearanceDate?.vocabulary)
            ? (_openBlock(), _createBlock(_component_ion_col, {
                key: 2,
                sizeXs: "12",
                sizeSm: "12",
                sizeMd: "8",
                sizeLg: "6",
                sizeXl: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_subtitle, { class: "ion-padding-start ion-padding-end ion-padding-bottom ion-text-center" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Other items will only appear when it is the right time. For example, the vocabulary \"" + _toDisplayString(_ctx.singleLeitnerItemEarlierToBoxAppearanceDate.vocabulary.word) + "\" will appear on " + _toDisplayString(_ctx.format(_ctx.singleLeitnerItemEarlierToBoxAppearanceDate.boxAppearanceDate, 'LLLL, dd')) + ". ", 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isNetworkError)
            ? (_openBlock(), _createBlock(_component_network_error, { key: 3 }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_infinite_scroll, {
            onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => (_ctx.renderBoxItems($event))),
            threshold: "100px",
            id: "infinite-scroll",
            disabled: _ctx.isDisabled
          }, {
            default: _withCtx(() => [
              _createVNode(_component_spinner)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}