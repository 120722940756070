import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!

  return (_openBlock(), _createElementBlock("view", {
    class: "container ion-justify-content-center ion-padding-top ion-padding-bottom",
    style: _normalizeStyle(_ctx.inlineStyle)
  }, [
    _createVNode(_component_ion_spinner, {
      "aria-label": "Loading",
      color: "primary",
      name: "lines",
      class: "firecracker-spinner"
    })
  ], 4))
}