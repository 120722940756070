import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "display-flex ion-justify-content-center ion-padding-bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_wifi_off_icon = _resolveComponent("wifi-off-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_card_subtitle, { class: "ion-padding ion-text-center" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.message || _ctx.defaultMessage), 1)
      ]),
      _: 1
    }),
    _createElementVNode("view", _hoisted_1, [
      _createVNode(_component_wifi_off_icon, {
        "fill-color-variable": '--ion-color-primary',
        "stroke-color-variable": '--ion-color-primary'
      })
    ])
  ], 64))
}