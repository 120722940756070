import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19a5e404"),n=n(),_popScopeId(),n)
const _hoisted_1 = { slot: "content" }
const _hoisted_2 = { slot: "content" }
const _hoisted_3 = { slot: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!

  return (_openBlock(), _createBlock(_component_ion_popover, {
    "is-open": _ctx.isPopoverOpened,
    onDidDismiss: _ctx.closeSettingsPopover,
    class: "settings-popover"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { class: "settings-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list_header, { class: "header-container" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_title, { class: "title" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Settings ")
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                shape: "round",
                size: "small",
                color: "primary",
                class: "close",
                onClick: _ctx.closeSettingsPopover
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: _ctx.faTimesCircle,
                    class: "close-icon"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion_group, {
            class: "contents-container",
            multiple: true,
            value: ['first']
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_accordion, {
                value: "first",
                class: "ion-padding-bottom"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    slot: "header",
                    lines: "inset"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_subtitle, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Searching Preference ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchingOptions, (value, option) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        key: option,
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_toggle, {
                            justify: "space-between",
                            checked: _ctx.innerVocabularySearchCoverage[option],
                            disabled: value.isDisabled,
                            onIonChange: ($event: any) => (_ctx.setSearchingOption(option, $event.detail.checked))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(value.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["checked", "disabled", "onIonChange"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_accordion, {
                value: "second",
                class: "ion-padding-bottom"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    slot: "header",
                    lines: "inset"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_subtitle, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Filtering Preference ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_toggle, {
                          justify: "space-between",
                          checked: _ctx.innerFetchNotHavingDefinitionOnly,
                          onIonChange: _cache[0] || (_cache[0] = ($event: any) => (
                                    _ctx.setSelectedFiltering('innerFetchNotHavingDefinitionOnly', $event.detail.checked)
                                ))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Show only draft vocab ")
                          ]),
                          _: 1
                        }, 8, ["checked"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_toggle, {
                          justify: "space-between",
                          checked: _ctx.innerFetchFlashcard,
                          onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setSelectedFiltering('innerFetchFlashcard', $event.detail.checked)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Show flashcard in boxes ")
                          ]),
                          _: 1
                        }, 8, ["checked"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_accordion, {
                value: "third",
                class: "ion-padding-bottom"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    slot: "header",
                    lines: "inset"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_subtitle, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Sorting Preference ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_ion_radio_group, {
                      value: _ctx.innerSelectedSort,
                      onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setSelectedSortingOption($event.detail.value)))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortingOptions, (label, value) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: value,
                            lines: "none"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_radio, {
                                justify: "space-between",
                                value: value
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(label), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list_header, { class: "footer-container" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                color: "success",
                fill: "solid",
                class: "apply-button",
                onClick: _ctx.onApplyingSettings
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Apply ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open", "onDidDismiss"]))
}