<template>
    <view class="display-flex ion-justify-content-center">
        <!-- all credit goes to https://en.wikipedia.org/wiki/Leitner_system#/media/File:Leitner_system.svg -->
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="360px"
            height="200px"
            viewBox="-17.16 -35.952 460 250"
        >
            <defs></defs>
            <g>
                <g>
                    <g>
                        <path
                            fill="var(--ion-color-primary)"
                            d="M32.363,63.602c0.639-4.496,2.271-8.827,4.646-12.64c2.373-3.822,5.463-7.137,8.976-9.841
				c7.044-5.42,15.733-8.385,24.47-8.844c8.727-0.481,17.655,1.487,25.292,5.962c0.953,0.559,1.885,1.156,2.792,1.791l0.846,0.607
				l0.344,0.258c0.458,0.345,0.909,0.701,1.351,1.066c0.886,0.731,1.741,1.502,2.561,2.311c0.82-2.28,1.487-4.655,1.997-7.102
				c2.699,4.403,4.549,9.128,5.376,13.813c0.839,4.68,0.637,9.287-0.47,13.326c-2.131-3.617-4.943-6.43-7.982-8.421
				c-3.042-2-6.318-3.199-9.515-3.77c1.937-1.243,3.985-2.358,6.134-3.328c-0.684-0.674-1.399-1.319-2.142-1.933
				c-0.371-0.307-0.75-0.606-1.136-0.896l-0.29-0.217l-0.714-0.512c-0.766-0.536-1.555-1.041-2.363-1.515
				c-6.478-3.8-14.17-5.521-21.74-5.101c-7.558,0.393-15.012,2.97-20.938,7.535c-5.926,4.52-10.21,11.129-11.209,18.351
				L32.363,63.602z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            fill="var(--ion-color-primary)"
                            d="M116.637,63.603c0.638-4.496,2.271-8.827,4.646-12.64c2.372-3.822,5.463-7.137,8.976-9.841
				c7.043-5.42,15.733-8.385,24.47-8.844c8.727-0.481,17.655,1.487,25.291,5.962c0.953,0.559,1.885,1.156,2.792,1.791l0.847,0.607
				l0.344,0.258c0.458,0.345,0.909,0.7,1.352,1.066c0.886,0.731,1.741,1.501,2.561,2.311c0.82-2.28,1.486-4.655,1.997-7.101
				c2.699,4.402,4.549,9.127,5.376,13.813c0.839,4.68,0.637,9.287-0.469,13.326c-2.131-3.617-4.944-6.43-7.983-8.421
				c-3.042-2-6.317-3.199-9.515-3.77c1.938-1.242,3.985-2.358,6.134-3.328c-0.683-0.674-1.398-1.319-2.142-1.933
				c-0.371-0.307-0.75-0.606-1.136-0.896l-0.29-0.217l-0.714-0.512c-0.766-0.536-1.555-1.041-2.363-1.515
				c-6.479-3.8-14.171-5.521-21.74-5.101c-7.558,0.393-15.012,2.97-20.939,7.535c-5.926,4.52-10.21,11.129-11.209,18.35
				L116.637,63.603z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            fill="var(--ion-color-primary)"
                            d="M205.357,63.602c0.639-4.496,2.271-8.827,4.646-12.64c2.373-3.822,5.463-7.137,8.976-9.841
				c7.044-5.42,15.733-8.385,24.47-8.844c8.727-0.481,17.655,1.487,25.292,5.962c0.953,0.559,1.885,1.156,2.792,1.791l0.847,0.607
				l0.344,0.258c0.458,0.345,0.909,0.7,1.352,1.066c0.886,0.731,1.741,1.501,2.561,2.311c0.82-2.28,1.487-4.655,1.997-7.101
				c2.699,4.402,4.55,9.127,5.376,13.813c0.84,4.68,0.637,9.287-0.469,13.326c-2.132-3.617-4.944-6.43-7.983-8.421
				c-3.042-2-6.317-3.199-9.515-3.77c1.937-1.242,3.984-2.358,6.134-3.328c-0.684-0.674-1.398-1.319-2.142-1.933
				c-0.371-0.307-0.75-0.606-1.136-0.896l-0.29-0.217l-0.714-0.512c-0.766-0.536-1.555-1.041-2.363-1.515
				c-6.478-3.8-14.171-5.521-21.74-5.101c-7.558,0.393-15.012,2.97-20.938,7.535c-5.927,4.52-10.21,11.129-11.209,18.351
				L205.357,63.602z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            fill="var(--ion-color-primary)"
                            d="M289.631,63.603c0.639-4.496,2.271-8.827,4.646-12.64c2.372-3.822,5.463-7.137,8.976-9.841
				c7.044-5.42,15.733-8.385,24.47-8.844c8.727-0.481,17.655,1.487,25.292,5.962c0.953,0.559,1.885,1.156,2.792,1.791l0.847,0.607
				l0.344,0.258c0.458,0.345,0.909,0.7,1.352,1.066c0.886,0.731,1.741,1.501,2.561,2.311c0.82-2.28,1.487-4.655,1.997-7.101
				c2.699,4.402,4.55,9.127,5.376,13.813c0.84,4.68,0.637,9.287-0.469,13.326c-2.132-3.617-4.944-6.43-7.983-8.421
				c-3.042-2-6.317-3.199-9.515-3.77c1.937-1.242,3.984-2.358,6.134-3.328c-0.684-0.674-1.398-1.319-2.142-1.933
				c-0.371-0.307-0.75-0.606-1.136-0.896l-0.29-0.217l-0.714-0.512c-0.766-0.536-1.555-1.041-2.363-1.515
				c-6.478-3.8-14.17-5.521-21.74-5.101c-7.558,0.393-15.012,2.97-20.938,7.535c-5.926,4.52-10.21,11.129-11.209,18.35
				L289.631,63.603z"
                        />
                    </g>
                </g>
            </g>
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="11.5"
                y1="63.881"
                x2="11.5"
                y2="77.381"
            />
            <rect
                x="0.5"
                y="77.381"
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                width="52"
                height="29"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="0.5"
                y1="77.381"
                x2="11.5"
                y2="63.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="52.5"
                y1="77.381"
                x2="63.5"
                y2="63.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="52.5"
                y1="106.382"
                x2="63.5"
                y2="92.882"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="11.5"
                y1="63.881"
                x2="63.5"
                y2="63.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="63.5"
                y1="63.881"
                x2="63.5"
                y2="92.882"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="94.5"
                y1="63.881"
                x2="94.5"
                y2="77.381"
            />
            <rect
                x="83.5"
                y="77.381"
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                width="52"
                height="29.001"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="83.5"
                y1="77.381"
                x2="94.5"
                y2="63.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="135.5"
                y1="77.381"
                x2="146.5"
                y2="63.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="135.5"
                y1="106.382"
                x2="146.5"
                y2="92.882"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="94.5"
                y1="63.881"
                x2="146.5"
                y2="63.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="146.5"
                y1="63.881"
                x2="146.5"
                y2="92.882"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="182.5"
                y1="63.881"
                x2="182.5"
                y2="77.381"
            />
            <rect
                x="171.5"
                y="77.381"
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                width="52"
                height="29.001"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="171.5"
                y1="77.381"
                x2="182.5"
                y2="63.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="223.5"
                y1="77.381"
                x2="234.5"
                y2="63.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="223.5"
                y1="106.382"
                x2="234.5"
                y2="92.882"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="182.5"
                y1="63.881"
                x2="234.5"
                y2="63.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="234.5"
                y1="63.881"
                x2="234.5"
                y2="92.882"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="275.5"
                y1="62.881"
                x2="275.5"
                y2="76.381"
            />
            <rect
                x="264.5"
                y="76.381"
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                width="52"
                height="29"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="264.5"
                y1="76.381"
                x2="275.5"
                y2="62.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="316.5"
                y1="76.381"
                x2="327.5"
                y2="62.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="316.5"
                y1="105.382"
                x2="327.5"
                y2="91.882"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="275.5"
                y1="62.881"
                x2="327.5"
                y2="62.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="327.5"
                y1="62.881"
                x2="327.5"
                y2="91.882"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="361.5"
                y1="63.881"
                x2="361.5"
                y2="77.381"
            />
            <rect
                x="350.5"
                y="77.381"
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                width="52"
                height="29"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="350.5"
                y1="77.381"
                x2="361.5"
                y2="63.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="402.5"
                y1="77.381"
                x2="413.5"
                y2="63.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="402.5"
                y1="106.382"
                x2="413.5"
                y2="92.882"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="361.5"
                y1="63.881"
                x2="413.5"
                y2="63.881"
            />
            <line
                fill="none"
                stroke="var(--ion-color-primary)"
                stroke-miterlimit="10"
                x1="413.5"
                y1="63.881"
                x2="413.5"
                y2="92.882"
            />
            <g>
                <g>
                    <g>
                        <path
                            fill="var(--ion-color-warning)"
                            d="M378.802,107.966c-0.703,4.871-2.53,9.548-5.171,13.63c-2.639,4.093-6.064,7.606-9.933,10.439
				c-7.757,5.683-17.234,8.648-26.693,8.923c-9.45,0.295-19.075-2.052-27.199-7.15c-1.014-0.636-2.003-1.313-2.964-2.033
				l-0.712-0.544l-0.168-0.132l-0.335-0.268c-0.445-0.358-0.881-0.728-1.313-1.103c-0.861-0.754-1.69-1.546-2.486-2.373
				c-0.883,2.257-1.617,4.603-2.209,7.025c-2.534-4.443-4.251-9.178-5-13.85c-0.763-4.668-0.524-9.254,0.57-13.294
				c2.141,3.611,4.925,6.455,7.952,8.511c3.027,2.063,6.285,3.369,9.507,4.064c-1.982,1.176-4.069,2.232-6.244,3.141
				c0.669,0.695,1.368,1.364,2.097,2.002c0.365,0.317,0.735,0.63,1.113,0.935l1.033,0.802c0.817,0.612,1.663,1.191,2.531,1.735
				c6.957,4.373,15.343,6.446,23.634,6.185c8.285-0.236,16.522-2.85,23.132-7.697c3.298-2.414,6.171-5.379,8.351-8.761
				c2.183-3.376,3.654-7.17,4.224-11.102L378.802,107.966z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            fill="var(--ion-color-warning)"
                            d="M288.429,107.966c-0.703,4.871-2.529,9.548-5.171,13.63c-2.639,4.093-6.063,7.606-9.933,10.439
				c-7.757,5.683-17.234,8.648-26.692,8.923c-9.451,0.295-19.076-2.052-27.199-7.15c-1.015-0.636-2.004-1.313-2.965-2.033
				l-0.711-0.544l-0.169-0.132l-0.335-0.268c-0.444-0.358-0.881-0.728-1.313-1.103c-0.861-0.754-1.69-1.546-2.485-2.373
				c-0.883,2.258-1.618,4.603-2.21,7.025c-2.534-4.443-4.25-9.178-4.999-13.85c-0.763-4.668-0.525-9.254,0.57-13.294
				c2.141,3.611,4.925,6.455,7.952,8.511c3.028,2.063,6.285,3.369,9.506,4.064c-1.981,1.176-4.069,2.232-6.243,3.141
				c0.668,0.695,1.367,1.364,2.096,2.002c0.366,0.317,0.735,0.63,1.113,0.935l1.033,0.802c0.818,0.612,1.663,1.191,2.531,1.736
				c6.957,4.372,15.343,6.445,23.635,6.184c8.284-0.236,16.522-2.85,23.132-7.697c3.297-2.414,6.171-5.379,8.35-8.76
				c2.183-3.377,3.654-7.171,4.224-11.103L288.429,107.966z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            fill="var(--ion-color-warning)"
                            d="M193.288,107.966c-0.703,4.871-2.53,9.548-5.171,13.63c-2.639,4.093-6.064,7.606-9.933,10.439
				c-7.757,5.682-17.234,8.648-26.693,8.923c-9.451,0.295-19.075-2.052-27.199-7.15c-1.014-0.636-2.003-1.313-2.964-2.033
				l-0.711-0.544l-0.169-0.132l-0.335-0.268c-0.444-0.358-0.881-0.728-1.313-1.103c-0.861-0.754-1.69-1.546-2.485-2.373
				c-0.883,2.258-1.618,4.603-2.21,7.025c-2.534-4.443-4.25-9.178-4.999-13.85c-0.763-4.668-0.525-9.254,0.57-13.294
				c2.141,3.611,4.925,6.455,7.952,8.511c3.027,2.063,6.285,3.369,9.506,4.064c-1.982,1.176-4.069,2.232-6.244,3.141
				c0.669,0.695,1.368,1.364,2.097,2.002c0.366,0.317,0.735,0.63,1.113,0.935l1.033,0.802c0.818,0.612,1.663,1.191,2.531,1.736
				c6.958,4.372,15.343,6.445,23.634,6.184c8.285-0.236,16.522-2.851,23.132-7.698c3.297-2.414,6.171-5.378,8.351-8.76
				c2.183-3.376,3.654-7.17,4.224-11.102L193.288,107.966z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            fill="var(--ion-color-warning)"
                            d="M102.916,107.966c-0.703,4.871-2.53,9.548-5.171,13.63c-2.639,4.093-6.064,7.606-9.933,10.439
				c-7.757,5.683-17.235,8.648-26.693,8.923c-9.451,0.295-19.076-2.052-27.2-7.15c-1.014-0.636-2.003-1.313-2.964-2.033
				l-0.711-0.544l-0.169-0.132l-0.335-0.268c-0.444-0.358-0.881-0.728-1.313-1.103c-0.861-0.754-1.69-1.546-2.485-2.373
				c-0.883,2.258-1.618,4.603-2.21,7.025c-2.534-4.443-4.25-9.178-4.999-13.85c-0.763-4.668-0.525-9.254,0.57-13.294
				c2.141,3.611,4.925,6.455,7.952,8.511c3.027,2.063,6.285,3.369,9.506,4.064c-1.982,1.176-4.069,2.232-6.244,3.141
				c0.669,0.695,1.368,1.364,2.097,2.002c0.366,0.317,0.735,0.63,1.113,0.935l1.033,0.802c0.818,0.612,1.663,1.191,2.531,1.736
				c6.958,4.372,15.343,6.445,23.634,6.184c8.285-0.236,16.522-2.85,23.132-7.697c3.297-2.414,6.171-5.379,8.35-8.761
				c2.183-3.376,3.654-7.17,4.224-11.102L102.916,107.966z"
                        />
                    </g>
                </g>
            </g>
            <rect x="19.5" y="83.131" fill="none" width="14" height="19.5" />
            <rect x="103.542" y="83.131" fill="none" width="14" height="19.5" />
            <rect x="190.5" y="82.131" fill="none" width="14" height="19.5" />
            <rect x="285.773" y="82.131" fill="none" width="14" height="19.5" />
            <rect x="368.66" y="83.132" fill="none" width="14" height="19.5" />
            <rect x="73.023" y="3.048" fill="none" width="270" height="20.5" />
            <text
                transform="matrix(1 0 0 1 73.0234 20.0879)"
                fill="var(--ion-card-sub-title-color)"
                font-family="'MyriadPro-Bold'"
                font-size="1.5rem"
            >
                I know
            </text>
            <rect x="59.816" y="151.048" fill="none" width="290.16" height="20.5" />
            <text
                transform="matrix(1 0 0 1 59.8164 168.0879)"
                fill="var(--ion-card-sub-title-color)"
                font-family="'MyriadPro-Bold'"
                font-size="1.5rem"
            >
                I forgot
            </text>
            <text
                transform="rotate(90 156.67187500000003,89.64843750000001) "
                font-family="'MyriadPro-Bold'"
                font-size="1.5rem"
                y="98.154839"
                x="151"
                fill="var(--ion-color-fern-green)"
            >
                *
            </text>
            <text
                transform="rotate(90 249.34375,90.93750000000003) "
                font-family="'MyriadPro-Bold'"
                font-size="1.5rem"
                y="101.4375"
                x="235"
                fill="var(--ion-color-fern-green)"
            >
                **
            </text>
        </svg>
    </view>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LeitnerSystemsFlow',
});
</script>
<style scoped></style>
