import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_firecracker_header = _resolveComponent("firecracker-header")!
  const _component_dictionary = _resolveComponent("dictionary")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_firecracker_header, {
        "header-title": _ctx.$route.params.word,
        "content-id": "dictionary",
        "menu-id": "dictionary-menu",
        type: "BACK"
      }, null, 8, ["header-title"]),
      _createVNode(_component_ion_content, {
        fullscreen: true,
        id: "dictionary"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "display-flex ion-justify-content-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, {
                sizeXs: "12",
                sizeSm: "12",
                sizeMd: "10",
                sizeLg: "8",
                sizeXl: "8"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_dictionary, {
                    word: _ctx.$route.params.word,
                    "label-alignment": 'center'
                  }, null, 8, ["word"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}