<template>
    <!-- all credit goes to https://thenounproject.com/icon/box-5605255/ -->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 125" x="0px" y="0px">
        <g data-name="Layer 2">
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M50,10.927a1.5,1.5,0,0,0,1.5-1.5V7.5a1.5,1.5,0,0,0-3,0V9.427A1.5,1.5,0,0,0,50,10.927Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M50,21.528a1.5,1.5,0,0,0,1.5-1.5V18.1a1.5,1.5,0,0,0-3,0v1.927A1.5,1.5,0,0,0,50,21.528Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M54.337,15.264h1.927a1.5,1.5,0,0,0,0-3H54.337a1.5,1.5,0,0,0,0,3Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M43.736,15.264h1.927a1.5,1.5,0,0,0,0-3H43.736a1.5,1.5,0,0,0,0,3Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M77.241,35.053a1.5,1.5,0,0,0,1.5-1.5V31.625a1.5,1.5,0,0,0-3,0v1.928A1.5,1.5,0,0,0,77.241,35.053Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M77.241,45.653a1.5,1.5,0,0,0,1.5-1.5V42.226a1.5,1.5,0,0,0-3,0v1.927A1.5,1.5,0,0,0,77.241,45.653Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M81.578,39.389h1.927a1.5,1.5,0,0,0,0-3H81.578a1.5,1.5,0,0,0,0,3Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M70.977,36.389a1.5,1.5,0,0,0,0,3H72.9a1.5,1.5,0,0,0,0-3Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M22.759,35.053a1.5,1.5,0,0,0,1.5-1.5V31.625a1.5,1.5,0,0,0-3,0v1.928A1.5,1.5,0,0,0,22.759,35.053Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M22.759,45.653a1.5,1.5,0,0,0,1.5-1.5V42.226a1.5,1.5,0,0,0-3,0v1.927A1.5,1.5,0,0,0,22.759,45.653Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M27.1,36.389a1.5,1.5,0,1,0,0,3h1.927a1.5,1.5,0,1,0,0-3Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M16.5,39.389h1.927a1.5,1.5,0,1,0,0-3H16.5a1.5,1.5,0,0,0,0,3Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M55.061,59.369a1.5,1.5,0,0,0,1.49,1.349,1.48,1.48,0,0,0,.148-.007,1.5,1.5,0,0,0,1.347-1.637C58.03,58.9,56.6,41.523,74.818,23.8a1.5,1.5,0,0,0-2.092-2.151C53.412,40.443,54.984,58.605,55.061,59.369Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M15.567,56.687l9.466,2.491-7.756,6.748a1.5,1.5,0,0,0,.267,2.449l8.017,4.364v9.739a1.5,1.5,0,0,0,.9,1.374L49.4,93.875l.007,0a1.5,1.5,0,0,0,1.188,0l.007,0L73.54,83.852a1.5,1.5,0,0,0,.9-1.374V72.739l8.017-4.364a1.5,1.5,0,0,0,.267-2.449l-7.756-6.748,9.466-2.491a1.5,1.5,0,0,0,.249-2.812l-19.4-8.994A1.5,1.5,0,0,0,64.018,47.6l15.624,7.243L72,56.858l-8.626-4.131a1.5,1.5,0,1,0-1.3,2.706l6.238,2.986L50,66.821l-18.311-8.4,6.238-2.986a1.5,1.5,0,1,0-1.3-2.706l-8.626,4.131-7.647-2.012L35.982,47.6a1.5,1.5,0,0,0-1.261-2.722l-19.4,8.994a1.5,1.5,0,0,0,.249,2.812ZM71.439,81.5,51.5,90.208V78.879a1.5,1.5,0,0,0-3,0V90.208L28.561,81.5V74.372L39.26,80.2a1.5,1.5,0,0,0,1.8-.277L50,70.634l8.942,9.286a1.5,1.5,0,0,0,1.8.277l10.7-5.825Zm.151-21.28,7.535,6.556L60.31,77.015l-7.764-8.062Zm-43.18,0,19.044,8.737L39.69,77.015,20.875,66.772Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M41.954,59.072a1.5,1.5,0,0,0,1.343,1.64c.051,0,.1.008.152.008a1.5,1.5,0,0,0,1.49-1.351c.077-.764,1.649-18.926-17.665-37.718A1.5,1.5,0,0,0,25.182,23.8C43.351,41.479,41.978,58.813,41.954,59.072Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M50,62.775a1.5,1.5,0,0,0,1.5-1.5v-31.8a1.5,1.5,0,0,0-3,0v31.8A1.5,1.5,0,0,0,50,62.775Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M59.964,26.781a1.838,1.838,0,1,0-1.838-1.838A1.839,1.839,0,0,0,59.964,26.781Zm0-3A1.163,1.163,0,1,1,58.8,24.943,1.163,1.163,0,0,1,59.964,23.781Z"
            />
            <path
                :fill="`var(${fillColorVariable})`"
                :stroke="`var(${strokeColorVariable})`"
                d="M40.036,26.781A1.838,1.838,0,1,0,38.2,24.943,1.84,1.84,0,0,0,40.036,26.781Zm0-3a1.163,1.163,0,1,1-1.162,1.162A1.164,1.164,0,0,1,40.036,23.781Z"
            />
        </g>
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'BoxIcon',
    props: ['fillColorVariable', 'strokeColorVariable'],
});
</script>
<style scoped></style>
