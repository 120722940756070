<template>
    <!-- all credit goes to https://thenounproject.com/icon/think-3968238/ -->
    <svg
        xmlns:x="http://ns.adobe.com/Extensibility/1.0/"
        xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/"
        xmlns:graph="http://ns.adobe.com/Graphs/1.0/"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 100 125"
        style="enable-background: new 0 0 100 100"
        xml:space="preserve"
    >
        <switch>
            <foreignObject
                requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
                x="0"
                y="0"
                width="1"
                height="1"
            />
            <g i:extraneous="self">
                <g>
                    <path
                        :fill="`var(${fillColorVariable})`"
                        :stroke="`var(${strokeColorVariable})`"
                        d="M70.8,66.4c0.6-1.1,0.2-2.4-0.8-3c-1.1-0.6-2.4-0.2-3,0.8c-0.6,1.1-2.5,1.2-3.2,0c-0.6-1.1-1.9-1.4-3-0.8     c-1.1,0.6-1.4,1.9-0.8,3c1.1,2,3.2,3.2,5.4,3.2C67.7,69.6,69.7,68.4,70.8,66.4z"
                    />
                    <path
                        :fill="`var(${fillColorVariable})`"
                        :stroke="`var(${strokeColorVariable})`"
                        d="M84.5,63.4c-1.1-0.6-2.4-0.2-3,0.8c-0.6,1.1-2.5,1.2-3.2,0c-0.6-1.1-1.9-1.4-3-0.8c-1.1,0.6-1.4,1.9-0.8,3     c1.1,2,3.2,3.2,5.4,3.2c2.2,0,4.3-1.2,5.4-3.2C85.9,65.4,85.5,64,84.5,63.4z"
                    />
                    <path
                        :fill="`var(${fillColorVariable})`"
                        :stroke="`var(${strokeColorVariable})`"
                        d="M48.3,23.2c0-4.4-2.2-8.4-5.9-10.8C41,6.7,35.9,2.7,30,2.7c-1.6,0-3.1,0.3-4.6,0.8c-1.5-0.6-3-0.8-4.6-0.8     c-6,0-11,4-12.4,9.7c-3.7,2.4-5.9,6.4-5.9,10.8c0,4.4,2.2,8.4,5.9,10.8c1.4,5.7,6.5,9.7,12.4,9.7c1.6,0,3.1-0.3,4.6-0.8     c1.5,0.6,3,0.8,4.6,0.8c6,0,11-4,12.4-9.7C46,31.6,48.3,27.6,48.3,23.2z M39.1,30.2c-0.7,0.4-1.2,1-1.3,1.8     c-0.6,3.8-3.9,6.7-7.8,6.7c-1.2,0-2.4-0.3-3.5-0.8c-0.3-0.2-0.7-0.3-1.1-0.3s-0.7,0.1-1.1,0.3c-1.1,0.5-2.3,0.8-3.5,0.8     c-3.9,0-7.2-2.9-7.8-6.7c-0.1-0.8-0.6-1.4-1.3-1.8c-2.6-1.4-4.3-4.1-4.3-7s1.6-5.7,4.3-7c0.7-0.4,1.2-1,1.3-1.8     c0.6-3.8,3.9-6.7,7.8-6.7c1.2,0,2.4,0.3,3.5,0.8c0.7,0.3,1.5,0.3,2.2,0c1.1-0.5,2.3-0.8,3.5-0.8c3.9,0,7.2,2.9,7.8,6.7     c0.1,0.8,0.6,1.4,1.3,1.8c2.6,1.4,4.3,4.1,4.3,7S41.7,28.9,39.1,30.2z"
                    />
                    <path
                        :fill="`var(${fillColorVariable})`"
                        :stroke="`var(${strokeColorVariable})`"
                        d="M52.4,51.2c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9C51.1,54.1,52.4,52.8,52.4,51.2z"
                    />
                    <path
                        :fill="`var(${fillColorVariable})`"
                        :stroke="`var(${strokeColorVariable})`"
                        d="M42.2,42.4c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2C45.4,43.8,44,42.4,42.2,42.4z"
                    />
                    <path
                        :fill="`var(${fillColorVariable})`"
                        :stroke="`var(${strokeColorVariable})`"
                        d="M72.6,47.5c-13.7,0-24.9,11.2-24.9,24.9s11.2,24.9,24.9,24.9c13.7,0,24.9-11.2,24.9-24.9S86.3,47.5,72.6,47.5z      M72.6,92.4c-11,0-20-9-20-20s9-20,20-20c11,0,20,9,20,20S83.6,92.4,72.6,92.4z"
                    />
                    <path
                        :fill="`var(${fillColorVariable})`"
                        :stroke="`var(${strokeColorVariable})`"
                        d="M81.1,76c-1.2-0.7-2.7-0.3-3.3,0.9c-1,1.8-3,3-5.1,3c-2.1,0-4-1.1-5.1-3c-0.7-1.2-2.2-1.6-3.3-0.9     c-1.2,0.7-1.6,2.2-0.9,3.3c1.9,3.4,5.5,5.4,9.3,5.4c3.9,0,7.5-2.1,9.4-5.4C82.7,78.2,82.2,76.7,81.1,76z"
                    />
                    <path
                        :fill="`var(${fillColorVariable})`"
                        :stroke="`var(${strokeColorVariable})`"
                        d="M15.8,20.1c-1.7,0-3.2,1.4-3.2,3.2c0,1.7,1.4,3.2,3.2,3.2c1.7,0,3.2-1.4,3.2-3.2C18.9,21.5,17.5,20.1,15.8,20.1z"
                    />
                    <circle
                        :fill="`var(${fillColorVariable})`"
                        :stroke="`var(${strokeColorVariable})`"
                        cx="25.4"
                        cy="23.2"
                        r="3.2"
                    />
                    <path
                        :fill="`var(${fillColorVariable})`"
                        :stroke="`var(${strokeColorVariable})`"
                        d="M35,20.1c-1.7,0-3.2,1.4-3.2,3.2c0,1.7,1.4,3.2,3.2,3.2c1.7,0,3.2-1.4,3.2-3.2C38.2,21.5,36.7,20.1,35,20.1z"
                    />
                </g>
            </g>
        </switch>
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ThinkingIcon',
    props: ['fillColorVariable', 'strokeColorVariable'],
});
</script>
<style scoped></style>
