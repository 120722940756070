import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "25",
  height: "25",
  viewBox: "0 0 50 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["fill", "stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("rect", {
      width: "48",
      height: "48",
      fill: `var(${_ctx.fillColorVariable})`,
      "fill-opacity": "0.01"
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M28 12V4L8 14V42L20 36",
      stroke: `var(${_ctx.strokeColorVariable})`,
      "stroke-width": "4",
      "stroke-linejoin": "bevel"
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M20 16L40 6V34L20 44V16Z",
      fill: `var(${_ctx.fillColorVariable})`,
      stroke: `var(${_ctx.strokeColorVariable})`,
      "stroke-width": "4",
      "stroke-linejoin": "bevel"
    }, null, 8, _hoisted_4)
  ]))
}