import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_firecracker_header = _resolveComponent("firecracker-header")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_dictionary = _resolveComponent("dictionary")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_vocabulary_details = _resolveComponent("vocabulary-details")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_firecracker_header, {
        "header-title": _ctx.word,
        "content-id": "linker-word",
        "menu-id": "linker-word-menu",
        type: "BACK"
      }, null, 8, ["header-title"]),
      _createVNode(_component_ion_content, {
        fullscreen: true,
        id: "linker-word"
      }, {
        default: _withCtx(() => [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.displayDictionary)
            ? (_openBlock(), _createBlock(_component_ion_row, {
                key: 1,
                class: "display-flex ion-justify-content-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    sizeXs: "12",
                    sizeSm: "12",
                    sizeMd: "10",
                    sizeLg: "8",
                    sizeXl: "8"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_dictionary, {
                        word: _ctx.word,
                        "label-alignment": 'center'
                      }, null, 8, ["word"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.displayDictionary)
            ? (_openBlock(), _createBlock(_component_vocabulary_details, {
                key: 2,
                word: _ctx.word,
                vocabulary: _ctx.vocabulary,
                "disable-inserting-into-leitner-box": false,
                "is-loading": _ctx.isLoading
              }, null, 8, ["word", "vocabulary", "is-loading"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}