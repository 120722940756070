<template>
    <!-- all credit goes to https://uxwing.com/ -->
    <svg viewBox="0 0 122.88 117.85" width="122.88" height="87.85" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill="none" id="canvas_background" height="514" width="514" y="-1" x="-1" />
        </g>
        <g>
            <g id="svg_1">
                <path
                    :fill="`var(${colorVariable})`"
                    id="svg_2"
                    d="m60.054146,68.41l-42.03,-22.08l-11.82,14.14c14.91,7.88 29.82,15.76 44.74,23.65l9.11,-15.71l0,0zm3.25,-44.94l-42.18,20.92l41.12,21.61l41.2,-21.65l-23.63,-12.36l-16.51,-8.52l0,0zm-47.41,20.48l-15.5,-14.68c-0.57,-0.65 -0.51,-1.65 0.15,-2.23c0.09,-0.08 0.2,-0.15 0.3,-0.21l47.11,-26.65c0.75,-0.4 1.68,-0.12 2.1,0.61l13.23,18.22l8.82,-16.52c0.41,-0.77 1.36,-1.07 2.13,-0.66l47.8,25.27c0.14,0.08 0.27,0.17 0.39,0.29c0.61,0.62 0.6,1.62 -0.02,2.23l-14.16,13.91l13.71,16.41c0.56,0.67 0.47,1.67 -0.2,2.22c-0.1,0.08 -0.21,0.15 -0.32,0.21l-12.31,6.51l0,24.4c0,0.66 -0.4,1.22 -0.98,1.46l-44.51,22.59c-0.29,0.32 -0.71,0.52 -1.17,0.52c-0.62,0 -1.15,-0.35 -1.41,-0.87l-44.59,-22.3c-0.55,-0.28 -0.87,-0.83 -0.87,-1.41l0,-24.27l-12.54,-6.63c-0.11,-0.06 -0.22,-0.13 -0.32,-0.21c-0.67,-0.56 -0.76,-1.56 -0.2,-2.22l13.36,-15.99l0,0zm44.95,-22.89l-12.73,-17.39l-44.04,25.03l14.39,13.4l42.38,-21.04l0,0zm4.91,0.02l14.75,7.72l25.08,12.93l13.07,-12.84l-44.5,-23.54l-8.4,15.73l0,0zm40.72,25.25l-42.03,22.08l9.11,15.7l44.74,-23.65l-11.82,-14.13l0,0z"
                />
            </g>
        </g>
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EmptyBox',
    props: ['colorVariable'],
});
</script>
<style scoped></style>
