enum NativeStorageKey {
    JWT = 'JWT',
    AUTHORIZED_USER = 'AUTHORIZED_USER',
    SHOULD_RELOAD_VOCABULARIES = 'SHOULD_RELOAD_VOCABULARIES',
    UPDATED_VOCABULARY = 'UPDATED_VOCABULARY',
    SET_LEITNER_BOX_EXISTENCE = 'SET_LEITNER_BOX_EXISTENCE',
    VOCAB_SETTINGS = 'VOCAB_SETTINGS',
    SHOULD_RELOAD_LEITNER_ITEMS = 'SHOULD_RELOAD_LEITNER_ITEMS',
    GOOD_BYE = 'GOOD_BYE',
    GUESSING_GAME_VOCABULARIES = 'GUESSING_GAME_VOCABULARIES',
}

export default NativeStorageKey;
