import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_empty_box_icon = _resolveComponent("empty-box-icon")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    button: true,
    class: "transparent margin-top-bottom-unset",
    onClick: _ctx.navigate
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, { class: "no-padding" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "display-flex ion-justify-content-center icon-container" }, {
            default: _withCtx(() => [
              _createVNode(_component_empty_box_icon, {
                "color-variable": _ctx.colors[_ctx.box]
              }, null, 8, ["color-variable"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { class: "display-flex ion-justify-content-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_subtitle, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.mappedBoxWithDays[`BOX_${_ctx.box}`]) + " Box ", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { class: "display-flex ion-justify-content-center count-container" }, {
            default: _withCtx(() => [
              (_ctx.count === -1 && _ctx.error === false)
                ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
                : _createCommentVNode("", true),
              (_ctx.count !== -1 && _ctx.error === false)
                ? (_openBlock(), _createBlock(_component_ion_card_subtitle, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.count) + " " + _toDisplayString(_ctx.count > 1 ? 'Vocabs' : 'Vocab'), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick"]))
}