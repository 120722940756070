import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_firecracker_menu = _resolveComponent("firecracker-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, { class: "margin-bottom-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, {
                  size: "12",
                  class: "display-flex"
                }, {
                  default: _withCtx(() => [
                    (_ctx.type === 'MENU')
                      ? (_openBlock(), _createBlock(_component_ion_menu_button, {
                          key: 0,
                          "aria-label": "Menu",
                          onClick: _ctx.onClickMenuIcon
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, { icon: _ctx.faBars }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (['BACK', 'MANUAL_BACK'].includes(_ctx.type))
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 1,
                          "aria-label": "Back to the previous page",
                          shape: "round",
                          fill: "clear",
                          class: "back-icon",
                          onClick: _ctx.onClickBackIcon
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, { icon: _ctx.faArrowLeft }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (!_ctx.enableSearching)
                      ? (_openBlock(), _createBlock(_component_ion_title, {
                          key: 2,
                          class: "overflowed-content"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.headerTitle), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.enableSearching)
                      ? (_openBlock(), _createBlock(_component_ion_searchbar, {
                          key: 3,
                          mode: "ios",
                          enterkeyhint: "search",
                          placeholder: _ctx.searchPlaceholder,
                          value: _ctx.searchKeyword,
                          debounce: 1000,
                          animated: true,
                          onIonInput: _ctx.onChangeSearchKeyword
                        }, null, 8, ["placeholder", "value", "onIonInput"]))
                      : _createCommentVNode("", true),
                    (_ctx.enableSettings)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 4,
                          "aria-label": "Vocabulary settings preferences",
                          size: "small",
                          shape: "round",
                          fill: "clear",
                          class: "settings-button",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modifySettings()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, { icon: _ctx.faEllipsisV }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_firecracker_menu, {
      ref: "FirecrackerMenuRef",
      "content-id": _ctx.contentId,
      "menu-id": _ctx.menuId
    }, null, 8, ["content-id", "menu-id"])
  ], 64))
}